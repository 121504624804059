<template>
  <div>
    <vs-button
      :class="btnClass"
      icon="people"
      :size="btnSize"
      :type="btnStyle"
      @click="openParentDialog"
    >
      Destinatários de teste
    </vs-button>

    <!-- Parent Dialog -->
    <vs-popup
      title="Destinatários de Teste"
      :active.sync="parentDialog"
    >
      <div>
        <vs-card class="recipients-dialog">
          <div class="d-flex align-items-end flex-column">
            <header class="w-100 d-flex align-items-center justify-content-between">
              <vs-input
                v-model="search"
                icon="search"
                icon-after="true"
              />

              <vs-button
                size="small"
                icon="add"
                @click="openAddTestDialog"
              >
                Criar Destinatário
              </vs-button>
            </header>

            <vs-list>
              <vs-list-header
                color="secondary"
                title="Destinatários de teste"
              />

              <vs-list-item
                v-for="(testRec, index) in filteredTestRecs"
                :key="index"
                :title="`${testRec.nome1 || ''} ${testRec.nome2 || ''}`"
                icon="person"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="text-sm text-right pb-1">
                      <span v-if="!testRec.email">
                        <b>Email:</b> <span class="text-muted">N/I</span>
                      </span>
                      <span v-else>
                        <b>Email:</b> {{ testRec.email }}
                      </span>
                    </span>

                    <div class="text-sm text-right pb-1">
                      <span v-if="!testRec.cel">
                        <b>Telefone:</b> <small class="text-muted">N/I</small>
                      </span>
                      <span v-else>
                        <b>Telefone:</b> {{ testRec.cel }}
                      </span>
                    </div>
                  </div>

                  <div class="d-flex align-items-center ml-2">
                    <vs-button
                      class="mx-1"
                      color="#525252"
                      icon="edit"
                      size="small"
                      type="flat"
                      @click="openEditTestDialog(testRec, index)"
                    />

                    <vs-button
                      color="#525252"
                      icon="delete"
                      size="small"
                      type="flat"
                      @click="openDeleteTestDialog(testRec, index)"
                    />
                  </div>
                </div>
              </vs-list-item>
            </vs-list>
          </div>
        </vs-card>

        <footer class="w-100 d-flex justify-content-end">
          <vs-button
            color="primary"
            @click="closeParentDialog"
          >
            Fechar
          </vs-button>
        </footer>

        <!-- Create Test Recipient -->
        <vs-popup
          title="Novo Destinatário de Teste"
          :active.sync="addDialog"
        >
          <vs-card class="add-test-dialog">
            <vs-row>
              <vs-col
                class="pb-3"
                vs-align="center"
                vs-w="6"
              >
                <vs-input
                  v-model="testName1"
                  label="Nome*"
                />
              </vs-col>

              <vs-col
                class="pb-3"
                vs-align="center"
                vs-w="6"
              >
                <vs-input
                  v-model="testName2"
                  label="Sobrenome"
                />
              </vs-col>

              <vs-col
                class="pb-3"
                vs-align="center"
                vs-w="6"
              >
                <vs-input
                  v-model="testDocument"
                  label="CPF"
                />
              </vs-col>

              <vs-col
                class="pb-3"
                vs-align="center"
                vs-w="6"
              >
                <vs-input
                  v-model="testPhone"
                  label="Telefone"
                />
              </vs-col>

              <vs-col
                class="pb-3"
                vs-align="center"
                vs-w="6"
              >
                <vs-input
                  v-model="testEmail"
                  label="Email"
                />
              </vs-col>
            </vs-row>

            <small>*Indica campos obrigatórios.</small>
          </vs-card>

          <footer class="w-100 d-flex justify-content-end">
            <vs-button
              class="mr-2"
              color="primary"
              @click="closeAddTestDialog"
            >
              Cancelar
            </vs-button>

            <vs-button
              color="secondary"
              :disabled="!addTestRecValid"
              text-color="black"
              @click="addTestRecipient"
            >
              Criar Destinatário
            </vs-button>
          </footer>
        </vs-popup>

        <!-- Edit Test Recipient -->
        <vs-popup
          title="Editar Destinatário de Teste"
          :active.sync="editDialog"
        >
          <vs-card class="edit-test-dialog">
            <vs-row>
              <vs-col
                class="pb-3"
                vs-align="center"
                vs-w="6"
              >
                <vs-input
                  v-model="updatedTestName1"
                  label="Nome*"
                />
              </vs-col>

              <vs-col
                class="pb-3"
                vs-align="center"
                vs-w="6"
              >
                <vs-input
                  v-model="updatedTestName2"
                  label="Sobrenome"
                />
              </vs-col>

              <vs-col
                class="pb-3"
                vs-align="center"
                vs-w="6"
              >
                <vs-input
                  v-model="updatedTestDoc"
                  label="CPF"
                />
              </vs-col>

              <vs-col
                class="pb-3"
                vs-align="center"
                vs-w="6"
              >
                <vs-input
                  v-model="updatedTestPhone"
                  label="Telefone"
                />
              </vs-col>

              <vs-col
                class="pb-3"
                vs-align="center"
                vs-w="6"
              >
                <vs-input
                  v-model="updatedTestEmail"
                  label="Email"
                />
              </vs-col>
            </vs-row>
          </vs-card>

          <footer class="w-100 d-flex justify-content-end">
            <vs-button
              class="mr-2"
              color="primary"
              @click="closeEditTestDialog"
            >
              Cancelar
            </vs-button>

            <vs-button
              color="secondary"
              :disabled="!editTestRecValid"
              text-color="black"
              @click="updateTestRecipient"
            >
              Atualizar Destinatário
            </vs-button>
          </footer>
        </vs-popup>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Services */
import Campaign from '../../../../services/campaign';

const campaignService = new Campaign();

export default {
  name: 'RecipientsMgmt',
  props: {
    btnClass: {
      type: String,
      default: '',
    },
    btnSize: {
      type: String,
      default: 'default',
    },
    btnStyle: {
      type: String,
      default: 'filled',
    },
  },
  data: () => ({
    /* Data */
    testRecipients: [],
    search: '',
    selectedTestRec: {},
    selectedIndex: -1,
    /* New Test Recipient */
    testEmail: '',
    testName1: '',
    testName2: '',
    testPhone: '',
    testDocument: '',
    /* Update Test Recipient */
    updatedTestEmail: '',
    updatedTestName1: '',
    updatedTestName2: '',
    updatedTestPhone: '',
    updatedTestDoc: '',
    /* Popups */
    addDialog: false,
    editDialog: false,
    parentDialog: false,
  }),
  computed: {
    addTestRecValid() {
      const validEmail = (
        this.testEmail.length > 0
        && this.testEmail.includes('@')
      );

      const validPhone = (
        this.testPhone.length >= 11
        && this.testPhone.length <= 15
      );

      const hasPhoneOrEmail = validEmail || validPhone;

      return (
        hasPhoneOrEmail
        && this.testName1.length >= 3
      );
    },
    editTestRecValid() {
      const validEmail = (
        this.updatedTestEmail.length > 0
        && this.updatedTestEmail.includes('@')
      );

      const validPhone = (
        this.updatedTestPhone.length >= 11
        && this.updatedTestPhone.length <= 15
      );

      const hasPhoneOrEmail = validEmail || validPhone;

      return (
        hasPhoneOrEmail
        && this.updatedTestName1.length >= 3
      );
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
    filteredTestRecs() {
      if (this.testRecipients.length === 0) {
        return [];
      } else if (this.search.length > 0) {
        const searchTerm = this.search.toLowerCase();

        let filtered = [...this.testRecipients];

        filtered = filtered.filter((recipient) => {
          let matches = false;

          if (recipient.cel) {
            const celSearch = String(recipient.cel).toLowerCase();
            if (celSearch.includes(searchTerm)) matches = true;
          }

          if (recipient.email) {
            const emailSearch = String(recipient.email).toLowerCase();
            if (emailSearch.includes(searchTerm)) matches = true;
          }

          if (recipient.nome1) {
            const nameSearch1 = String(recipient.nome1).toLowerCase();
            if (nameSearch1.includes(searchTerm)) matches = true;
          }

          if (recipient.nome2) {
            const nameSearch2 = String(recipient.nome2).toLowerCase();
            if (nameSearch2.includes(searchTerm)) matches = true;
          }

          return matches;
        });

        return filtered;
      } else {
        return this.testRecipients;
      }
    },
  },
  mounted() {
    this.getTestRecipients();
  },
  methods: {
    /* API */
    getTestRecipients() {
      campaignService.getTestRecipients(
        this.ownerId,
      )
        .then((res) => this.testRecipients = res)
        .catch((err) => alert(err));
    },
    addTestRecipient() {
      const newTestRec = {
        ownerId: this.ownerId,
        email: this.testEmail,
        nome1: this.testName1,
        nome2: this.testName2,
        cel: this.testPhone,
        cpf: this.testDocument,
      };

      const payload = [
        ...this.testRecipients,
        newTestRec,
      ];

      campaignService.updateTestRecipients(
        this.ownerId,
        payload,
      )
        .then(() => this.getTestRecipients())
        .catch((err) => alert(err))
        .finally(() => this.closeAddTestDialog());
    },
    deleteTestRecipient() {
      if (this.selectedIndex !== -1) {
        let updated = [...this.testRecipients];

        updated.splice(this.selectedIndex, 1);

        campaignService.updateTestRecipients(
          this.ownerId,
          updated,
        )
          .then(() => this.getTestRecipients())
          .catch((err) => alert(err))
          .finally(() => this.clearSelectedTestRec());
      }
    },
    updateTestRecipient() {
      if (this.selectedIndex !== -1) {
        let updated = [...this.testRecipients];

        updated[this.selectedIndex] = {
          ownerId: this.ownerId,
          email: this.updatedTestEmail,
          nome1: this.updatedTestName1,
          nome2: this.updatedTestName2,
          cel: this.updatedTestPhone,
          cpf: this.updatedTestDoc,
        };

        campaignService.updateTestRecipients(
          this.ownerId,
          updated,
        )
          .then(() => this.getTestRecipients())
          .catch((err) => alert(err))
          .finally(() => this.closeEditTestDialog());
      }
    },
    /* Helpers */
    clearAddTestForm() {
      this.testEmail = '';
      this.testName1 = '';
      this.testName2 = '';
      this.testPhone = '';
      this.testDocument = '';
    },
    clearEditTestForm() {
      this.updatedTestEmail = '';
      this.updatedTestName1 = '';
      this.updatedTestName2 = '';
      this.updatedTestPhone = ''
      this.updatedTestDoc = '';
    },
    clearSelectedTestRec() {
      this.selectedTestRec = {};
      this.selectedIndex = -1;
    },
    /* Popups */
    closeAddTestDialog() {
      this.clearAddTestForm();
      this.addDialog = false;
    },
    closeEditTestDialog() {
      this.clearSelectedTestRec();
      this.clearEditTestForm();
      this.editDialog = false;
    },
    closeParentDialog() {
      this.closeAddTestDialog();
      this.closeEditTestDialog();
      this.parentDialog = false;
    },
    openAddTestDialog() {
      this.addDialog = true;
    },
    openDeleteTestDialog(selected, index) {
      this.selectedTestRec = selected;
      this.selectedIndex = index;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Deletar Destinatário de Teste',
        text: `Tem certeza que deseja deletar: "${selected.nome1}"?`,
        cancelText: 'Cancelar',
        acceptText: 'Deletar',
        accept: this.deleteTestRecipient,
      });
    },
    openEditTestDialog(selected, index) {
      this.selectedTestRec = selected;
      this.selectedIndex = index;

      this.updatedTestEmail = selected.email || '';
      this.updatedTestName1 = selected.nome1 || '';
      this.updatedTestName2 = selected.nome2 || '';
      this.updatedTestPhone = selected.cel || '';
      this.updatedTestDoc = selected.cpf || '';

      this.editDialog = true;
    },
    openParentDialog() {
      this.parentDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
/* Custom Text */
.subheader {
  padding-bottom: 1rem;
}

.text-gray {
  color: #808080;
}

.text-sm {
  font-size: 0.825rem;
}
</style>
