<template>
  <div class="new-campaign">
    <!-- Options -->
    <section class="c-card d-md-flex align-items-center">
      <div class="row px-3 w-100">
        <section class="col-12 mt-2 w-100">
          <label class="text-uppercase title small font-weight-bold">
            1 - Opções de envio
          </label>

          <div class="mt-2">
            <NotificationButtons
              :notification-type="campaignType"
              @set-notification-type="setCampaignType"
            />

            <!-- Forms -->
            <div>
              <EmailForm
                v-if="campaignType === 1"
                :email-subject="emailSubject"
                :selected-sender="selectedSender"
                :selected-temp-id="templateId"
                :selected-temp-name="templateName"
                :selected-temp-url="templateUrl"
                @set-email-subject="(val) => emailSubject = val"
                @set-selected-sender="(val) => selectedSender = val"
                @set-selected-temp-id="(val) => templateId = val"
                @set-selected-temp-name="(val) => templateName = val"
                @set-selected-temp-url="(val) => templateUrl = val"
              />

              <!-- SMS Form -->
              <MessageForm
                v-if="campaignType === 2"
                notification-type="sms"
                :sms-code="smsCode"
                :sms-text="smsText"
                :submit-disabled="invalidMessage"
                @set-sms-code="(val) => smsCode = val"
                @set-sms-text="(val) => smsText = val"
                @set-submit-disabled="(val) => invalidMessage = val"
              />

              <!-- WhatsApp Form -->
              <MessageForm
                v-if="campaignType === 3"
                notification-type="whatsapp"
                :whats-code="whatsCode"
                :whats-text="whatsText"
                :submit-disabled="invalidMessage"
                @set-whats-code="(val) => whatsCode = val"
                @set-whats-text="(val) => whatsText = val"
                @set-submit-disabled="(val) => invalidMessage = val"
              />
            </div>

            <CampaignSettings
              :campaign-date="campaignDate"
              :campaign-time="campaignTime"
              :resend-enabled="resendEnabled"
              :schedule-enabled="scheduleEnabled"
              :unopened-days="unopenedDays"
              @set-campaign-date="(val) => campaignDate = val"
              @set-campaign-time="(val) => campaignTime = val"
              @set-resend-enabled="(val) => resendEnabled = val"
              @set-schedule-enabled="(val) => scheduleEnabled = val"
              @set-unopened-days="(val) => unopenedDays = val"
            />
          </div>
        </section>
      </div>
    </section>

    <!-- Recipients -->
    <section class="c-card d-md-flex align-items-center pb-4 px-3">
      <div class="w-100">
        <label class="text-uppercase title small font-weight-bold mt-2">
          2 - Destinatários
        </label>

        <div class="w-100 d-flex justify-content-left">
          <div class="col-4 mb-4">
            <label class="title small font-weight-bold mb-1 mr-2 mt-2 mb-2">
              Enviar para:
            </label>

            <vs-radio
              v-model="selectedRecipient"
              class="d-flex justify-content-start ml-0"
              :color="colorSecondary"
              vs-name="allBase"
              vs-value="allBase"
              @click="clearSelectedFilter"
            >
              Toda a base
            </vs-radio>

            <vs-radio
              v-model="selectedRecipient"
              class="d-flex justify-content-start ml-0"
              :color="colorSecondary"
              vs-name="filter"
              vs-value="filter"
            >
              Filtrar registros
            </vs-radio>

            <RecipientsMgmt
              btn-class="mt-2"
              btn-size="small"
              btn-style="filled"
            />
          </div>

          <!-- Filters -->
          <div class="col-4">
            <SelectFilter
              :filter-name="filterName"
              :selected-recipient="selectedRecipient"
              @get-id-count="getIdCount"
              @set-filter-id="(val) => filterId = val"
              @set-filter-name="(val) => filterName = val"
            />
          </div>

          <div class="col-4">
            <div class="d-flex flex-column align-items-center">
              <label
                class="contacts-counter title font-weight-bold mb-0"
                :style="{ color: colorSecondary }"
              >
                <span v-if="selectedRecipient === 'filter' && filterId != 0">
                  {{ filteredContacts }}
                </span>
                <span v-else>
                  {{ totalContacts }}
                </span>
              </label>

              <div>
                <label class="text-small ml-2 title font-weight-bold align-self-center">
                  DESTINATÁRIOS SELECIONADOS
                </label>

                <i class="mdi mdi-account mt-1 mx-1 fa fa-3x text-dark" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- General -->
    <section class="c-card pb-2">
      <label class="title text-uppercase small font-weight-bold">
        3 - Definições da campanha
      </label>

      <div class="d-flex align-content-center justify-content-between pb-2">
        <div class="col-12 col-md-5">
          <label
            for="campaign-name-input"
            class="title font-weight-bold"
          >
            Nome:
          </label>

          <vs-input
            id="campaign-name-input"
            v-model="campaignName"
            class="w-100 mb-2"
            icon-after="true"
            icon="mode_edit"
            placeholder="Campanha"
          />

          <label
            v-if="campaignId !== 0"
            class="title font-weight-bold"
          >
            ID: {{ campaignId }}
          </label>
        </div>

        <div class="col-12 col-md-7 pl-5">
          <vs-row
            class="px-0 mx-0"
            vs-align="center"
          >
            <vs-col
              vs-w="6"
              class="px-1"
            >
              <vs-button
                class="btn-block"
                :disabled="!validForm"
                @click="createCampaign"
              >
                <i class="mdi mdi-content-save mr-1" />

                Salvar Campanha
              </vs-button>
            </vs-col>

            <vs-col
              v-if="campaignId !== 0"
              vs-w="6"
            >
              <vs-row v-if="updatingList">
                <vs-col
                  class="vs-con-loading__container py-2"
                  type="flex"
                  vs-align="center"
                  vs-justify="center"
                  vs-w="3"
                >
                  <div
                    class="con-vs-loading vs-loading-background-transparent vs-loading-color-primary"
                    style="background: transparent; padding: 8px 0;"
                  >
                    <div
                      class="vs-loading default"
                      style="transform: scale(0.45);"
                    >
                      <div class="effect-1 effects" />
                      <div class="effect-2 effects" />
                      <div class="effect-3 effects" />
                    </div>
                  </div>
                </vs-col>

                <vs-col
                  class="py-2"
                  type="flex"
                  vs-align="center"
                  vs-justify="center"
                  vs-w="9"
                >
                  <span class="text-secondary">
                    Lista de destinatários em processamento...
                  </span>
                </vs-col>
              </vs-row>
              <vs-button
                v-else
                class="btn-block"
                @click="openConfirmPrompt"
              >
                <i class="mdi mdi-account-convert mr-1" />

                Atualizar Lista
              </vs-button>
            </vs-col>
          </vs-row>

          <vs-divider v-if="campaignId !== 0" />

          <!-- Submit -->
          <vs-row
            v-if="campaignId !== 0"
            class="px-0 mx-0"
          >
            <vs-col
              vs-w="6"
              class="px-1 d-flex align-items-center justify-content-center"
            >
              <label
                for="test-switch"
                class="title text-uppercase small font-weight-bold mb-0"
              >
                Envio de teste
              </label>

              <vs-switch
                id="test-switch"
                v-model="testSubmit"
                class="align-self-center ml-1"
                vs-icon="done"
              />

              <RecipientsMgmt
                btn-class="ml-2"
                btn-size="small"
                btn-style="flat"
              />
            </vs-col>

            <vs-col vs-w="6">
              <vs-tooltip
                v-if="updatingList"
                text="Por favor aguarde o processamento da lista de destinatários"
                delay=".3s"
              >
                <vs-button
                  class="btn-block"
                  :color="colorSecondary ? colorSecondary : 'primary'"
                  disabled
                  type="relief"
                >
                  <i class="mdi mdi-send mr-1" />

                  {{ testSubmit ? 'Enviar Teste' : 'Enviar Campanha' }}
                </vs-button>
              </vs-tooltip>
              <vs-button
                v-else
                class="btn-block"
                :color="colorSecondary ? colorSecondary : 'primary'"
                :disabled="!validForm"
                type="relief"
                @click="executeCampaign"
              >
                <i class="mdi mdi-send mr-1" />

                {{ testSubmit ? 'Enviar Teste' : 'Enviar Campanha' }}
              </vs-button>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </section>

    <!-- Confirm Update Prompt -->
    <vs-prompt
      :active.sync="confirmPrompt"
      title="Aviso"
      type="confirm"
      color="primary"
      accept-text="Cancelar"
      cancel-text="Continuar"
      @accept="closeConfirmPrompt"
      @cancel="updateMailingList"
    >
      <p
        :style="{
          fontSize: '1.09em',
          letterSpacing: '0.03rem',
        }"
      >
        Essa ação pode demorar alguns minutos dependendo da quantidade de registros.
        Por favor aguarde até o final do processo para o disparo da campanha.
      </p>
    </vs-prompt>
  </div>
</template>

<script>
/* Components */
import CampaignSettings from './CampaignSettings.vue';
import EmailForm from './EmailForm.vue';
import MessageForm from './MessageForm.vue';
import NotificationButtons from './NotificationButtons.vue';
import RecipientsMgmt from './RecipientsMgmt.vue';
import SelectFilter from './SelectFilter.vue';

/* Helpers */
import moment from 'moment';

/* Services */
import Campaign from '@/services/campaign';
import Fan from '@/services/fan';
import Filter from '@/services/filter';

const campaignService = new Campaign();
const fanService = new Fan();
const filterService = new Filter();

/* Style */
import './assets/new-campaign.scss';

export default {
  name: 'Campaign',
  components: {
    CampaignSettings,
    EmailForm,
    MessageForm,
    NotificationButtons,
    RecipientsMgmt,
    SelectFilter,
  },
  props: {
    edit: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    /* General */
    campaignId: 0,
    campaignType: 1,
    campaignDate: '',
    campaignName: '',
    campaignTime: '',
    /* Settings */
    unopenedDays: 0,
    resendEnabled: false,
    scheduleEnabled: false,
    testSubmit: true,
    updatingList: false,
    /* Contacts */
    filteredContacts: 0,
    totalContacts: 0,
    /* Filter */
    filterId: 0,
    filterName: '',
    selectedRecipient: 'allBase',
    /* Send Email */
    emailSubject: '',
    selectedSender: '',
    /* Send Message */
    smsCode: null,
    smsText: '',
    whatsCode: null,
    whatsText: '',
    invalidMessage: false,
    /* Templates */
    templateType: 1,
    // Type 1: Local File (default)
    templateId: 0,
    templateName: '',
    templateUrl: '',
    /* Popups */
    confirmPrompt: false,
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    projectId() {
      return this.$store.state.selectedProject;
    },
    selectedCampaign() {
      return this.$store.state.campInfo;
    },
    validForm() {
      const permitted = this.checkItemAccess('Campanhas');

      if (this.campaignType === 1) {
        return (
          permitted
          && this.emailSubject.length > 0
          && this.selectedSender.length > 0
        );
      }

      return permitted && !this.invalidMessage;
    },
    /* Style */
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    /* Date & Time */
    formattedDate() {
      const date = moment(this.campaignDate).format('yyyy-MM-DD');
      return !date.includes('Invalid') ? date : '';
    },
    formattedTime() {
      const time = moment(this.campaignTime).locale('pt').format('LT');
      return !time.includes('Invalid') ? time : '';
    },
  },
  created() {
    this.getFanbaseCount();

    // set selected campaign
    if (this.edit && this.selectedCampaign) {
      this.setSelCampaign();
    }
  },
  methods: {
    /* API */
    getFanbaseCount() {
      fanService.getIdsRegisterCount(this.ownerId, 0)
        .then((res) => this.totalContacts = parseInt(res))
        .catch((err) => this.errPrompt('Erro', err));
    },
    getIdCount(filterId) {
      if (filterId != 0) {
        this.$store.dispatch('set_isLoadingActive', true);

        fanService.getIdsRegisterCount(
          this.projectId,
          filterId,
        )
          .then((ret) => this.filteredContacts = parseInt(ret))
          .catch(() => {
            this.errPrompt(
              'Erro',
              'Não foi possível buscar a quantidade de registros.',
            );
          })
          .finally(() => this.$store.dispatch('set_isLoadingActive', false));
      } else {
        this.warnPrompt('Nenhuma opção de filtro selecionada.');
      }
    },
    /* Campaign */
    createCampaign() {
      if (this.selectedRecipient === 'allBase') this.filterId = 0;

      this.$store.dispatch('set_isLoadingActive', true);

      const templateCode = (
        this.campaignType === 2 ? this.smsCode
          : this.campaignType === 3 ? this.whatsCode
            : null
      );

      const message = (
        this.campaignType === 2 ? this.smsText
          : this.campaignType === 3 ? this.whatsText
            : ''
      );

      const payload = {
        ownerId: this.ownerId,
        id: this.campaignId,
        nome: this.campaignName,
        dataInicio: '',
        dataFim: '',
        filtro: this.filterId,
        templateType: this.templateType,
        fileID: this.templateId.toString(),
        templateUrl: '',
        emailRemetente: this.selectedSender,
        emailSubject: this.emailSubject,
        diasParaReenviar: parseInt(this.unopenedDays),
        teste: this.testSubmit,
        dataEnvio: this.formattedDate,
        horaEnvio: this.formattedTime,
        campaignType: this.campaignType,
        templateCode,
        message,
      };

      campaignService.createCampaign(payload)
        .then((ret) => this.campaignId = parseInt(ret))
        .catch((err) => this.warnPrompt(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    executeCampaign() {
      this.$store.dispatch('set_isLoadingActive', true);

      const mode = this.testSubmit ? 'teste' : 'real';

      campaignService.executeCampaign(this.campaignId, mode)
        .then(() => this.$vs.notify({ title: 'Campanha executada com sucesso' }))
        .catch((err) => this.errPrompt('Não foi possível criar a campanha', err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    updateMailingList() {
      this.updatingList = true;

      campaignService.updateMailingList(this.campaignId)
        .then(() => {
          this.$vs.notify({
            title: 'Lista atualizada.',
            text: 'Número:' + this.campaignId,
          });
        })
        .catch((err) => this.errPrompt('Não foi possível atualizar a lista!', err))
        .finally(() => (this.updatingList = false));
    },
    setSelCampaign() {
      const { config, status, tipo } = this.selectedCampaign.etapas[0];

      if (status === 6) this.updatingList = true;

      const hasSchedule = config.dataPlanejado && config.horaPlanejado;
      const unopenedDays = config.diasparaReenviar;

      // set general
      if (this.selectedCampaign.campanha.id) {
        this.campaignId = this.selectedCampaign.campanha.id;
      }

      this.campaignName = this.selectedCampaign.campanha.nome;

      // set schedule
      if (hasSchedule) {
        this.scheduleEnabled = true;
        this.campaignDate = config.dataPlanejado;
        this.campaignTime = config.horaPlanejado;
      }

      // set resend
      if (unopenedDays > 0) {
        this.resendEnabled = true;
        this.unopenedDays = unopenedDays;
      }

      // set filter
      if (this.selectedCampaign.campanha.filtro === 0) {
        this.testSubmit = false;
        this.clearSelectedFilter();
      } else if (this.selectedCampaign.campanha.filtro > 0) {
        this.setSelectedFilter();
      }

      // set campaign type
      if (this.selectedCampaign.etapas.length !== 0) {
        this.campaignType = tipo;

        // email
        if (tipo === 1) {
          this.emailSubject = config.subject;
          this.selectedSender = config.emailRemetente;
        }

        // sms
        if (tipo === 2) {
          this.smsCode = config.templateCode;
          this.smsText = config.msg;
        }

        // whatsapp
        if (tipo === 3) {
          this.whatsCode = config.templateCode;
          this.whatsText = config.msg;
        }
      }

      // set email template
      this.templateType = config.templateType;

      if (this.templateType === 1) {
        this.templateId = Number(config.fileID);
        this.templateUrl = config.templateUrl;

        if (config.templateName) {
          this.templateName = config.templateName;
        }
      }
    },
    setCampaignType(value) {
      this.campaignType = value;
    },
    /* Filter */
    setSelectedFilter() {
      this.selectedRecipient = 'filter';

      this.filterId = this.selectedCampaign.campanha.filtro;

      filterService.getFilters(this.ownerId)
        .then((res) => {
          const found = res.find((el) => el.filterId === this.filterId);
          this.filterName = found.name;
        })
        .catch((err) => this.errPrompt('Erro', err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));

      this.getIdCount(this.filterId);
    },
    clearSelectedFilter() {
      this.selectedRecipient = 'allBase';
      this.filterId = 0;
      this.filterName = '';
    },
    /* Helpers */
    checkItemAccess(access) {
      return this.$store.state.loginData.accessValues.includes(access);
    },
    /* Alerts */
    errPrompt(title, text) {
      this.$vs.dialog({
        title,
        text,
        color: 'danger',
        acceptText: 'OK',
      });
    },
    warnPrompt(text) {
      this.$vs.dialog({
        title: 'Aviso',
        text,
        color: 'warning',
        acceptText: 'OK',
      });
    },
    /* Popups */
    closeConfirmPrompt() {
      this.confirmPrompt = false;
    },
    openConfirmPrompt() {
      this.confirmPrompt = true;
    },
  },
};
</script>
