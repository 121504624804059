<template>
  <div class="mt-2">
    <hr>

    <label class="title small font-weight-bold mt-2">
      Configurações da Campanha:
    </label>

    <vs-row>
      <!-- Schedule -->
      <vs-col
        class="mt-2 d-flex"
        vs-lg="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-switch
          v-model="scheduleEnabledModel"
          vs-icon-on="done"
          @change="clearSchedule"
        />

        <label class="ml-2">
          Agendar envio
        </label>
      </vs-col>

      <vs-col>
        <vs-row>
          <vs-col
            v-if="scheduleEnabled"
            class="mt-2 mb-1"
            vs-lg="3"
            vs-sm="12"
            vs-xs="12"
          >
            <label class="title small font-weight-bold">
              Data de envio
            </label>

            <datetime
              v-model="campaignDateModel"
              class="theme-primary"
              input-class="vs-input--input"
              type="date"
              value-zone="America/Sao_Paulo"
              :auto="true"
              :min-datetime="dateTomorrow"
              :phrases="{ ok: '', cancel: '' }"
            />
          </vs-col>

          <vs-col
            v-if="scheduleEnabled"
            class="mt-2 mb-1"
            vs-lg="2"
            vs-sm="12"
            vs-xs="12"
          >
            <label class="title small font-weight-bold">
              Horário de envio
            </label>

            <datetime
              v-model="campaignTimeModel"
              class="theme-primary"
              input-class="vs-input--input"
              type="time"
              :auto="false"
              :phrases="{ ok: 'Confirmar', cancel: '' }"
            />
          </vs-col>
        </vs-row>
      </vs-col>

      <!-- Resend -->
      <vs-col
        type="flex"
        class="mt-2"
        vs-align="center"
        vs-justify="between"
        vs-lg="9"
        vs-xs="9"
      >
        <div class="d-flex align-content-center">
          <vs-checkbox
            v-model="resendEnabledModel"
            class="justify-content-start"
            @change="checkResubmit"
          >
            Reenviar, se não aberto após
          </vs-checkbox>

          <vs-input-number
            v-model="unopenedDaysModel"
            class="my-2 pt-1"
            :disabled="!resendEnabled"
            min="1"
          />

          <div class="align-self-center ml-2">
            dias.
          </div>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
/* Helpers */
import moment from 'moment';

export default {
  name: 'CampaignSettings',
  props: {
    campaignDate: {
      type: String,
      required: true,
    },
    campaignTime: {
      type: String,
      required: true,
    },
    unopenedDays: {
      type: Number,
      required: true,
    },
    resendEnabled: {
      type: Boolean,
      required: true,
    },
    scheduleEnabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    campaignDateModel: {
      get() { return this.campaignDate },
      set(value) { this.$emit('set-campaign-date', value) },
    },
    campaignTimeModel: {
      get() { return this.campaignTime },
      set(value) { this.$emit('set-campaign-time', value) },
    },
    unopenedDaysModel: {
      get() { return this.unopenedDays },
      set(value) { this.$emit('set-unopened-days', value) },
    },
    resendEnabledModel: {
      get() { return this.resendEnabled },
      set(value) { this.$emit('set-resend-enabled', value) },
    },
    scheduleEnabledModel: {
      get() { return this.scheduleEnabled },
      set(value) { this.$emit('set-schedule-enabled', value) },
    },
    /* Date & Time */
    dateTomorrow() {
      return moment().add(1, 'days').format('yyyy-MM-DD');
    },
  },
  methods: {
    /* Helpers */
    checkResubmit() {
      if (this.resend) {
        this.$emit('set-unopened-days', 3);
      } else {
        this.$emit('set-unopened-days', 0);
      }
    },
    clearSchedule() {
      this.$emit('set-campaign-date', '');
      this.$emit('set-campaign-time', '');
    },
  },
};
</script>
