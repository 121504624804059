<template>
  <div>
    <form v-if="selectedRecipient === 'filter'">
      <div class="d-flex flex-column mb-3">
        <label class="title small font-weight-bold mb-1">
          Filtro selecionado:
        </label>

        <vs-chip
          class="mb-2 align-self-start"
          color="secondary"
        >
          {{ filterName ? filterName : 'Nenhum filtro selecionado' }}
        </vs-chip>
      </div>

      <vs-button
        :disabled="selectedRecipient !== 'filter'"
        @click="openFiltersPopup"
      >
        <i class="fa fa-bars mr-1" />

        Selecionar Filtro
      </vs-button>
    </form>

    <!-- Select Filter Popup -->
    <vs-popup
      id="campaign-select-filter"
      :active.sync="filtersPopup"
      title="Meus Filtros"
    >
      <vs-table
        :data="filters"
        max-height="525px"
        :max-items="10"
        pagination
        :search="search"
        stripe
      >
        <template slot="thead">
          <vs-th sort-key="id">
            Id
          </vs-th>

          <vs-th sort-key="contactname">
            Nome
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            v-for="(filter, filterIdx) in data"
            :key="filterIdx"
          >
            <vs-td :data="filter.filterId">
              <h5
                class="mb-0 font-16 cursor-pointer"
                @click="selectFilter(filter)"
              >
                {{ filter.filterId }}
              </h5>
            </vs-td>

            <vs-td :data="filter.name">
              <h5
                class="mb-0 font-16 cursor-pointer"
                @click="selectFilter(filter)"
              >
                {{ filter.name }}
              </h5>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </div>
</template>

<script>
/* Services */
import Filter from '@/services/filter';

const filterService = new Filter();

export default {
  name: 'SelectFilter',
  props: {
    filterName: {
      type: String,
      required: true,
    },
    selectedRecipient: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    filters: [],
    search: '',
    /* Popups */
    filtersPopup: false,
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
  },
  created() {
    this.getFilters();
  },
  methods: {
    /* API */
    getFilters() {
      this.$store.dispatch('set_isLoadingActive', true);

      filterService.getFilters(this.ownerId)
        .then((ret) => this.filters = ret)
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Helpers */
    selectFilter(filter) {
      this.$vs.notify({
        title: 'Selecionado',
        text: `Filtro: ${filter.name}`,
      });

      this.$emit('get-id-count', filter.filterId);
      this.$emit('set-filter-id', filter.filterId);
      this.$emit('set-filter-name', filter.name);

      this.closeFiltersPopup();
    },
    /* Popups */
    closeFiltersPopup() {
      this.filtersPopup = false;
    },
    openFiltersPopup() {
      this.filtersPopup = true;
    },
  },
};
</script>

<style>
#campaign-select-filter .vs-popup--content {
  overflow: hidden !important;
}

#campaign-select-filter .vs-table--content {
  overflow-x: hidden !important;
}
</style>
