<template>
  <div class="mb-3">
    <label class="title small font-weight-bold">
      Selecione a ferramenta:
    </label>

    <div class="col-12">
      <div class="d-flex justify-content-start">
        <!-- Email Switch -->
        <div class="d-flex justify-content-center">
          <vs-button
            id="email"
            :color="getBtnColor('email')"
            data-toggle="tooltip"
            data-placement="top"
            style="border-radius: 54px"
            title="E-mail"
            :type="getBtnType('email')"
            @click="() => setNotificationType('email')"
          >
            <i class="mr-1 ml-1 mdi mdi-email-outline pt-1 pb-1" />
          </vs-button>

          <vs-switch
            v-model="selectedEmail"
            class="align-self-center ml-2"
            vs-icon="done"
          />
        </div>

        <!-- SMS Switch -->
        <div class="d-flex justify-content-center ml-2">
          <vs-button
            :color="getBtnColor('sms')"
            :type="getBtnType('sms')"
            data-placement="top"
            data-toggle="tooltip"
            style="border-radius: 54px"
            title="SMS"
            @click="() => setNotificationType('sms')"
          >
            <i class="mr-1 ml-1 mdi mdi-message-outline pt-1 pb-1" />
          </vs-button>

          <vs-switch
            v-model="selectedSMS"
            class="align-self-center ml-2"
            vs-icon="done"
          />
        </div>

        <!-- WhatsApp Switch -->
        <div class="d-flex justify-content-center ml-2">
          <vs-button
            :color="getBtnColor('whatsapp')"
            :type="getBtnType('whatsapp')"
            radius
            @click="() => setNotificationType('whatsapp')"
          >
            <i class="mr-1 ml-1 mdi mdi-whatsapp pt-1 pb-1" />
          </vs-button>

          <vs-switch
            v-model="selectedWhatsApp"
            vs-icon="done"
            class="align-self-center ml-2"
          />
        </div>

        <!-- Instagram Switch -->
        <div class="d-flex justify-content-center ml-2">
          <vs-button
            :color="getBtnColor('instagram')"
            :type="getBtnType('instagram')"
            disabled
            radius
            @click="() => setNotificationType('instagram')"
          >
            <i class="mr-1 ml-1 mdi mdi-instagram pt-1 pb-1" />
          </vs-button>

          <vs-switch
            v-model="selectedInstagram"
            class="align-self-center ml-2"
            disabled
            vs-icon="done"
          />
        </div>

        <!-- Facebook Switch -->
        <div class="d-flex justify-content-center ml-2">
          <vs-button
            :color="getBtnColor('facebook')"
            :type="getBtnType('facebook')"
            disabled
            radius
            @click="() => setNotificationType('facebook')"
          >
            <i class="mr-1 ml-1 mdi mdi-facebook pt-1 pb-1" />
          </vs-button>

          <vs-switch
            v-model="selectedFacebook"
            class="align-self-center ml-2"
            disabled
            vs-icon="done"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationButtons',
  props: {
    notificationType: {
      type: Number,
      required: true,
    },
  },
  computed: {
    notificationMap() {
      return {
        email: { type: 1, fillColor: '#615d5d' },
        sms: { type: 2, fillColor: '#615d5d' },
        whatsapp: { type: 3, fillColor: '#615d5d' },
        instagram: { type: 4, fillColor: '#615d5d' },
        facebook: { type: 5, fillColor: '#615d5d' },
        html: { type: 6, fillColor: '#615d5d' },
        url: { type: 7, fillColor: '#615d5d' },
        template: { type: 8, fillColor: '#615d5d' },
      };
    },
    selectedEmail: {
      get() { return this.isSelected('email') },
      set() { this.setNotificationType('email') },
    },
    selectedFacebook: {
      get() { return this.isSelected('facebook') },
      set() { this.setNotificationType('facebook') },
    },
    selectedInstagram: {
      get() { return this.isSelected('instagram') },
      set() { this.setNotificationType('instagram') },
    },
    selectedSMS: {
      get() { return this.isSelected('sms') },
      set() { this.setNotificationType('sms') },
    },
    selectedWhatsApp: {
      get() { return this.isSelected('whatsapp') },
      set() { this.setNotificationType('whatsapp') },
    },
  },
  methods: {
    /* Style */
    getBtnColor(value) {
      return this.notificationMap[value].fillColor;
    },
    getBtnType(value) {
      return this.isSelected(value) ? 'filled' : 'border';
    },
    /* Helpers */
    isSelected(value) {
      return this.notificationType === this.notificationMap[value].type;
    },
    setNotificationType(value) {
      const type = this.notificationMap[value].type;

      this.$emit('set-notification-type', type);
    },
  },
};
</script>
